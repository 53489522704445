import React, { Component } from 'react'
import { navigate } from 'gatsby';

class Store extends Component {

    componentDidMount() {
        navigate('/products')
    }

    render() {
        return <div></div>;
    }

}

export default Store;
